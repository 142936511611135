import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from "react-i18next";
import './CookieConsent.css';

const CookieConsent = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["superAppConsentCookie"]);
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const giveCookieConsent = (analytics = false) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
  
    setCookie("superAppConsentCookie", true, { path: "/", expires });
    if (analytics) {
      setCookie("superAppAnalyticsConsentCookie", true, { path: "/", expires });
      setAnalyticsAllowed(true);
      loadGoogleAnalytics();
    } else {
      setCookie("superAppAnalyticsConsentCookie", false, { path: "/", expires });
      setAnalyticsAllowed(false);
    }
    setIsModalOpen(false);
  };

  const loadGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HY6HS7LFGH';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-HY6HS7LFGH');
    };
  };


  return (
    <div className="cookie-consent">
      <p>
        {t("we_use_cookies")}{" "}
        <a href="#" onClick={() => setIsModalOpen(true)}>{t("learn_more")}</a>
      </p>
      <button onClick={() => giveCookieConsent(true)}>
        {t("accept_all_cookies")}
      </button>
      <button onClick={() => giveCookieConsent(false)}>
        {t("accept_essential_cookies")}
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            <h2>{t("cookie_policy_title")}</h2>
            <p>{t("cookie_policy_description")}</p>
            <ul>
              <li>{t("essential_cookies_info")}</li>
              <br />
              <li>{t("analytics_cookies_info")}</li>
            </ul>
            <div className="modal-buttons">
            <button onClick={() => setIsModalOpen(false)}>
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;
